import { EditLayout, ForeignEntityLayout, StringLayout } from '@3m5/crude-frontend/dist/shared/types/configurationTypes'

const editConfig = (): EditLayout => {
  const config: EditLayout = {
    version: 1,
    entities: [
      {
        entity: 'Employment',
        elements: [
          {
            name: 'id',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'displayName',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'description',
            layout: StringLayout.multiline,
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'area',
            layout: ForeignEntityLayout.autocomplete,
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 3,
                to: 3,
              },
            },
          },
          {
            name: 'eventDay',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'dayFrom',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'dayTo',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'helperNeeded',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 5,
                to: 5,
              },
            },
          },
          {
            name: 'helperRequirement',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 4,
                to: 4,
              },
            },
          },
          {
            name: 'assignedHelperAmount',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 4,
                to: 4,
              },
            },
          },
        ],
        editTabs: [
          {
            title: {
              'de-DE': 'Details',
              'en-US': 'details',
            },
            name: 'details',
            formGroups: [
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 0,
                  },
                  vertical: {
                    from: 0,
                    to: 3,
                  },
                },
                name: 'general',
                title: {
                  'de-DE': 'Allgemein',
                  'en-US': 'General',
                },
                initialOpen: true,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 1,
                    to: 1,
                  },
                  vertical: {
                    from: 0,
                    to: 3,
                  },
                },
                name: 'time',
                title: {
                  'de-DE': 'Einsatzzeit',
                  'en-US': 'Time',
                },
                initialOpen: true,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 1,
                  },
                  vertical: {
                    from: 4,
                    to: 5,
                  },
                },
                name: 'helper',
                title: {
                  'de-DE': 'Helfer',
                  'en-US': 'Helper',
                },
                initialOpen: true,
                closable: true,
              },
            ],
          },
        ],
      },
      {
        entity: 'Helper',
        elements: [
          {
            name: 'id',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'firstName',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'lastName',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'gender',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 3,
                to: 3,
              },
            },
          },
          {
            name: 'birthYear',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 4,
                to: 4,
              },
            },
          },
          {
            name: 'friend',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'shirtNeeded',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'helpedBefore',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'isClubMember',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 3,
                to: 3,
              },
            },
          },
          {
            name: 'notes',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 4,
                to: 4,
              },
            },
          },
          {
            name: 'registrationDate',
            editTab: 'details',
            position: {
              horizontal: {
                from: 2,
                to: 2,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'emailAddress',
            editTab: 'details',
            position: {
              horizontal: {
                from: 2,
                to: 2,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'telefonNumber',
            editTab: 'details',
            position: {
              horizontal: {
                from: 2,
                to: 2,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'address',
            layout: StringLayout.multiline,
            editTab: 'details',
            position: {
              horizontal: {
                from: 2,
                to: 2,
              },
              vertical: {
                from: 3,
                to: 4,
              },
            },
          },
          {
            name: 'availableOnFriday',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 5,
                to: 5,
              },
            },
          },
          {
            name: 'availableOnSaturday',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 6,
                to: 6,
              },
            },
          },
          {
            name: 'availableOnSunday',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 7,
                to: 7,
              },
            },
          },
          {
            name: 'fridayFrom',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 5,
                to: 5,
              },
            },
          },
          {
            name: 'saturdayFrom',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 6,
                to: 6,
              },
            },
          },
          {
            name: 'sundayFrom',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 7,
                to: 7,
              },
            },
          },
          {
            name: 'fridayTo',
            editTab: 'details',
            position: {
              horizontal: {
                from: 2,
                to: 2,
              },
              vertical: {
                from: 5,
                to: 5,
              },
            },
          },
          {
            name: 'saturdayTo',
            editTab: 'details',
            position: {
              horizontal: {
                from: 2,
                to: 2,
              },
              vertical: {
                from: 6,
                to: 6,
              },
            },
          },
          {
            name: 'sundayTo',
            editTab: 'details',
            position: {
              horizontal: {
                from: 2,
                to: 2,
              },
              vertical: {
                from: 7,
                to: 7,
              },
            },
          },
          {
            name: 'preferedArea',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 8,
                to: 8,
              },
            },
          },
          {
            name: 'assignedOnFriday',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 9,
                to: 9,
              },
            },
          },
          {
            name: 'assignedOnSaturday',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 9,
                to: 9,
              },
            },
          },
          {
            name: 'assignedOnSunday',
            editTab: 'details',
            position: {
              horizontal: {
                from: 2,
                to: 2,
              },
              vertical: {
                from: 9,
                to: 9,
              },
            },
          },
          {
            name: 'infoMailSentOn',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 10,
                to: 10,
              },
            },
          },
          {
            name: 'confirmationReceivedOn',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 10,
                to: 10,
              },
            },
          },
          {
            name: 'isConfirmed',
            editTab: 'details',
            position: {
              horizontal: {
                from: 2,
                to: 2,
              },
              vertical: {
                from: 10,
                to: 10,
              },
            },
          },
        ],
        editTabs: [
          {
            title: {
              'de-DE': 'Details',
              'en-US': 'Details',
            },
            description: {
              'de-DE': '',
              'en-US': 'Main content',
            },
            name: 'details',
            formGroups: [
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 1,
                  },
                  vertical: {
                    from: 0,
                    to: 4,
                  },
                },
                title: {
                  'de-DE': 'Allgemeine Daten',
                  'en-US': 'General Data',
                },
                name: 'generalData',
                initialOpen: true,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 2,
                    to: 2,
                  },
                  vertical: {
                    from: 0,
                    to: 4,
                  },
                },
                title: {
                  'de-DE': 'Kontakt',
                  'en-US': 'contact',
                },
                name: 'contact',
                initialOpen: true,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 2,
                  },
                  vertical: {
                    from: 5,
                    to: 7,
                  },
                },
                title: {
                  'de-DE': 'Verfügbarkeit',
                  'en-US': 'Availability',
                },
                name: 'availability',
                initialOpen: true,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 2,
                  },
                  vertical: {
                    from: 10,
                    to: 10,
                  },
                },
                title: {
                  'de-DE': 'Benachrichtigung',
                  'en-US': 'notification',
                },
                name: 'notification',
                initialOpen: true,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 2,
                  },
                  vertical: {
                    from: 8,
                    to: 9,
                  },
                },
                title: {
                  'de-DE': 'Einsatz',
                  'en-US': 'Employment',
                },
                name: 'employment',
                initialOpen: true,
                closable: true,
              },
            ],
          },
        ],
      },
    ],
  }
  return config
}
export default editConfig
