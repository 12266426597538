import { CrudeTab } from '@3m5/crude-frontend/dist/shared/types/configurationTypes'
import { Order } from '@3m5/crude-frontend/dist/shared/types/commonTypes'

const crudeTabConfig = (): CrudeTab[] => {
  return [
    {
      entity: 'Helper',
      standardFilter: [
        {
          columnName: 'availableOnFriday',
          multiSelect: false,
        },
        {
          columnName: 'assignedOnFriday',
          multiSelect: false,
        },
        {
          columnName: 'availableOnSaturday',
          multiSelect: false,
        },
        {
          columnName: 'assignedOnSaturday',
          multiSelect: false,
        },
        {
          columnName: 'availableOnSunday',
          multiSelect: false,
        },
        {
          columnName: 'assignedOnSunday',
          multiSelect: false,
        },
        {
          columnName: 'shirtSize',
          multiSelect: false,
        },
        {
          columnName: 'helpedBefore',
          multiSelect: false,
        },
        {
          columnName: 'isClubMember',
          multiSelect: false,
        },
        {
          columnName: 'isConfirmed',
          multiSelect: false,
        },
      ],
      columns: [
        {
          name: 'firstName',
          pinned: true,
        },
        {
          name: 'lastName',
          pinned: true,
          sort: Order.asc,
        },
        {
          name: 'preferedArea',
        },
        {
          name: 'availableOnFriday',
        },
        {
          name: 'fridayFrom',
        },
        {
          name: 'fridayTo',
        },
        {
          name: 'availableOnSaturday',
        },
        {
          name: 'saturdayFrom',
        },
        {
          name: 'saturdayTo',
        },
        {
          name: 'availableOnSunday',
        },
        {
          name: 'sundayFrom',
        },
        {
          name: 'sundayTo',
        },
        {
          name: 'assignedOnFriday',
        },
        {
          name: 'assignedOnSaturday',
        },
        {
          name: 'assignedOnSunday',
        },
        {
          name: 'shirtNeeded',
        },
        {
          name: 'isConfirmed',
          pinned: true,
        },
      ],
    },
    {
      entity: 'Employment',
      tabs: [
        {
          sourceEntity: 'Helper',
          sourceColumn: 'preferedArea',
          hideEntity: true,
        },
      ],
    },
    {
      entity: 'Area',
    },
    {
      entity: 'User',
    },
  ]
}
export default crudeTabConfig
