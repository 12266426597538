import React from 'react'
import {
  Stack, Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import Env from '../../shared/config/envConfig'
import FileDownloadIcon from '@mui/icons-material/FileDownload'

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '40px',
  },
  link: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: '20px',
  },
  container: {
    width: '360px',
  },
  icon: {
    marginRight: '10px',
  },
  headline: {
    fontWeight: 'bold',
  },
  area: {
    fontWeight: 'bold',
  },
}))

export enum Export {
  helper = 'helper',
  complete = 'complete',
  areas = 'area',
  registration = 'registration',
  plan = 'plan'
}

const Download: React.FC = () => {
  const classes = useStyles()

  const exportFile = (list: Export) => {
    const basePath = Env.basePath
    const url = `${basePath}/api/export/${list}`
    fetch(url,
      {
        method: 'GET',
        credentials: 'include',
      })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.target = '_blank'
        a.download = `${list}Export.xlsx`
        document.body.appendChild(a)
        a.click()
        a.remove()
      })
  }

  return (
    <Stack className={classes.root}>
      <Stack className={classes.container}>
        <Typography className={classes.link} variant='body1' onClick={() => exportFile(Export.helper)}><FileDownloadIcon className={classes.icon} /> <b>Liste aller Helfer</b></Typography>
      </Stack>
      <Stack className={classes.container}>
        <Typography className={classes.link} variant='body1' onClick={() => exportFile(Export.complete)}><FileDownloadIcon className={classes.icon} /> <b>Komplettliste für Abrechnung</b></Typography>
      </Stack>
      <Stack className={classes.container}>
        <Typography className={classes.link} variant='body1' onClick={() => exportFile(Export.plan)}><FileDownloadIcon className={classes.icon} /> <b>Liste aller Einsätze und Helfer</b></Typography>
      </Stack>
      {/* <Stack className={classes.container}> */}
      {/*  <Button className={classes.button} onClick={() => exportFile(Export.registration)}><FileDownloadIcon className={classes.icon} /> Zeitliche Anmeldung</Button> */}
      {/* </Stack> */}
    </Stack>
  )
}
export default Download
