import React, { useEffect, useState } from 'react'

import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { Dayjs } from 'dayjs'
import { Button, Grid, TextField, useTheme, useMediaQuery, Typography, Box } from '@mui/material'

import Env from '../../shared/config/envConfig'
import CheckboxComponent from '../../shared/components/FormComponents/CheckboxComponent'
import DropDownComponent, { EnumElement } from '../../shared/components/FormComponents/DropDownComponent'
import LabelWithTooltipComponent from '../../shared/components/FormComponents/LabelWithTooltipComponent'
import { useHistory } from 'react-router-dom'
import { setMessage } from '../../store/settings/settings.actions'
import { Severity } from '@3m5/crude-frontend/dist/shared/types/messageTypes'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const Registration = () => {
  const theme = useTheme()
  const history = useHistory()
  const downMd = useMediaQuery(theme.breakpoints.down('md'))

  // EmploymentList
  const [areaList, setAreaList] = useState<EnumElement[]>([])

  // required fields
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [telephoneNumber, setTelephoneNumber] = useState<string>('')
  const [emailAddress, setEmailAddress] = useState<string>('')
  const [address, setAddress] = useState<string>('')
  const [gender, setGender] = useState<EnumElement |undefined>()
  const [preferedArea, setPreferedArea] = useState<EnumElement |undefined>()
  const [shirt, setShirt] = useState<boolean>(false)
  const [birthYear, setBirthYear] = useState<string>('')

  // optional
  const [fridayFrom, setFridayFrom] = React.useState<Dayjs | null>(null)
  const [fridayTo, setFridayTo] = React.useState<Dayjs | null>(null)
  const [saturdayFrom, setSaturdayFrom] = React.useState<Dayjs | null>(null)
  const [saturdayTo, setSaturdayTo] = React.useState<Dayjs | null>(null)
  const [sundayFrom, setSundayFrom] = React.useState<Dayjs | null>(null)
  const [sundayTo, setSundayTo] = React.useState<Dayjs | null>(null)

  // at least one
  const [availableOnFriday, setAvailableOnFriday] = useState<boolean>(false)
  const [availableOnSaturday, setAvailableOnSaturday] = useState<boolean>(false)
  const [availableOnSunday, setAvailableOnSunday] = useState<boolean>(false)

  // other fields
  const [friend, setFriend] = useState<string>('')
  const [notes, setNotes] = useState<string>('')
  const [helpedBefore, setHelpedBefore] = useState<boolean>(false)
  const [isClubMember, setIsClubMember] = useState<boolean>(false)

  // helper
  const [disabled, setDisabled] = useState<boolean>(true)
  const [showHint, setShowHint] = useState<boolean>(false)
  const [showRequiredHints, setShowRequiredHints] = useState<boolean>(false)
  const [showYearHint, setShowYearHint] = useState<boolean>(false)
  const [showEmailHint, setShowEmailHint] = useState<boolean>(false)
  const [showTimeHint, setShowTimeHint] = useState<boolean>(false)
  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .trim()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
  }

  const checkValidity = () => {
    let temp = true
    if (availableOnFriday || availableOnSaturday || availableOnSunday) {
      temp = false
    }
    if (!firstName || !lastName || !telephoneNumber || !emailAddress || !address || !gender || !birthYear) {
      temp = true
    }
    if (parseInt(birthYear, 10) < 1900 || parseInt(birthYear, 10) > new Date().getFullYear()) {
      temp = true
    }
    if (emailAddress && !validateEmail(emailAddress)) {
      temp = true
    }
    if (fridayFrom?.isAfter(fridayTo) || saturdayFrom?.isAfter(saturdayTo) || sundayFrom?.isAfter(sundayTo)) {
      temp = true
    }
    setDisabled(temp)
  }

  useEffect(() => {
    checkValidity()
  }, [firstName, lastName, telephoneNumber, emailAddress, address, gender, shirt, birthYear])

  useEffect(() => {
    checkValidity()
    if (availableOnFriday || availableOnSaturday || availableOnSunday) {
      setShowHint(false)
    }
  }, [availableOnFriday, availableOnSaturday, availableOnSunday])

  useEffect(() => {
    checkValidity()
    if (parseInt(birthYear, 10) < 1900 || parseInt(birthYear, 10) > new Date().getFullYear()) {
      setShowYearHint(true)
    } else {
      setShowYearHint(false)
    }
  }, [birthYear])

  useEffect(() => {
    checkValidity()
    if (emailAddress && !validateEmail(emailAddress)) {
      setShowEmailHint(true)
    } else {
      setShowEmailHint(false)
    }
  }, [emailAddress])

  useEffect(() => {
    checkValidity()
    if (fridayFrom?.isAfter(fridayTo) || saturdayFrom?.isAfter(saturdayTo) || sundayFrom?.isAfter(sundayTo)) {
      setShowTimeHint(true)
    } else {
      setShowTimeHint(false)
    }
  }, [fridayFrom, fridayTo, saturdayFrom, saturdayTo, sundayFrom, sundayTo])

  const formatTime = (date: Dayjs | null) => {
    /**
    * This function includes a workaround to fix the timezone issue.
    * TODO: Remove this workaround after the issue is fixed in the backend.
    */
    console.log('timezone workaround applied')
    return date ? date.subtract(1, 'h').format('HH:mm') : null
  }

  const registerHelper = () => {
    const basePath = Env.basePath
    const restUrl = `${basePath}/api/import/helper`
    const requestHeaders: HeadersInit = new Headers()
    requestHeaders.set('Accept', 'application/json')
    requestHeaders.set('Content-Type', 'application/json')
    const newHelper = {
      helper: {
        firstName,
        lastName,
        birthYear,
        emailAddress,
        telephoneNumber,
        gender: gender?.key,
        address,
        availableOnFriday,
        availableOnSaturday,
        availableOnSunday,
        fridayFrom: formatTime(fridayFrom),
        fridayTo: formatTime(fridayTo),
        saturdayFrom: formatTime(saturdayFrom),
        saturdayTo: formatTime(saturdayTo),
        sundayFrom: formatTime(sundayFrom),
        sundayTo: formatTime(sundayTo),
        shirt,
        friend,
        helpedBefore,
        isClubMember,
        notes,
        preferedArea: preferedArea ? (preferedArea.key === 'null' ? null : preferedArea.key) : null,
      },
    }
    fetch(restUrl,
      {
        method: 'POST',
        credentials: 'include',
        headers: requestHeaders,
        body: JSON.stringify(newHelper),
      })
      .then(res => res.json())
      .then(() => {
        history.push('/thankyou')
      })
      .catch(() => {
        setMessage({
          severity: Severity.error,
          message: 'Etwas ist schief gegangen! Versuche es zu einem späteren Zeitpunkt erneut.',
        })
      })
  }

  const getAreaList = () => {
    const basePath = Env.basePath
    const restUrl = `${basePath}/api/import/areas`
    const requestHeaders: HeadersInit = new Headers()
    requestHeaders.set('Accept', 'application/json')
    requestHeaders.set('Content-Type', 'application/json')

    fetch(restUrl,
      {
        method: 'GET',
        credentials: 'include',
        headers: requestHeaders,
      })
      .then(res => res.json())
      .then((body) => {
        const list: EnumElement[] = [{
          key: 'null',
          value: 'Sonstige',
        }]
        body.areas.forEach((area: any) => {
          list.push({
            key: area.id,
            value: `${area.displayName}`,
          })
        })
        setAreaList(list)
      })
      .catch(() => {
        setMessage({
          severity: Severity.error,
          message: 'Etwas ist schief gegangen! Versuche es zu einem späteren Zeitpunkt erneut.',
        })
      })
  }

  useEffect(() => {
    getAreaList()
  }, [],
  )

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='de'>
      <Box sx={{ margin: '50px' }}>
        <Typography sx={{ marginTop: '20px' }} variant='body1'>Mit * markierte Pflichtfelder sind im Anmeldeformular
          auszufüllen
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}><Typography sx={{ marginTop: '20px' }} variant='h5'>Allgemeine Informationen</Typography>
          </Grid>
          <Grid item xs={downMd ? 12 : 6}>
            <TextField
              margin='normal'
              required
              error={showRequiredHints && !firstName}
              helperText={showRequiredHints && !firstName ? 'Vorname ist ein Pflichfeld' : ''}
              fullWidth
              id='firstname'
              label='Vorname'
              name='firstname'
              value={firstName}
              onChange={(event) => setFirstName(event.currentTarget.value)}
            />
          </Grid>
          <Grid item xs={downMd ? 12 : 6}>
            <TextField
              margin='normal'
              required
              fullWidth
              error={showRequiredHints && !lastName}
              helperText={showRequiredHints && !lastName ? 'Nachname ist ein Pflichfeld' : ''}
              id='laststname'
              label='Nachname'
              name='laststname'
              value={lastName}
              onChange={(event) => setLastName(event.currentTarget.value)}
            />
          </Grid>
          <Grid item xs={downMd ? 12 : 6}>
            <TextField
              margin='normal'
              required
              fullWidth
              error={(showRequiredHints && !emailAddress) || showEmailHint}
              helperText={showRequiredHints && !emailAddress ? 'E-Mail ist ein Pflichfeld' : (showEmailHint ? 'Die eingegebene E-Mail-Adresse ist nicht gültig' : '')}
              id='email'
              label='E-Mail'
              name='email'
              value={emailAddress}
              onChange={(event) => setEmailAddress(event.currentTarget.value.trim())}
            />
          </Grid>
          <Grid item xs={downMd ? 12 : 6}>
            <TextField
              margin='normal'
              required
              fullWidth
              id='phone'
              error={showRequiredHints && !telephoneNumber}
              helperText={showRequiredHints && !telephoneNumber ? 'Telefon ist ein Pflichfeld' : ''}
              label='Telefon'
              name='phone'
              value={telephoneNumber}
              onChange={(event) => setTelephoneNumber(event.currentTarget.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin='normal'
              required
              fullWidth
              error={showRequiredHints && !address}
              helperText={showRequiredHints && !address ? 'Wohnort ist ein Pflichfeld' : ''}
              id='city'
              label='Wohnort'
              name='city'
              value={address}
              onChange={(event) => setAddress(event.currentTarget.value)}
            />
          </Grid>
          <Grid item xs={downMd ? 12 : 4}>
            <TextField
              margin='normal'
              required
              fullWidth
              error={(showRequiredHints && !birthYear) || showYearHint}
              helperText={showRequiredHints && !birthYear ? 'Geburtsjahr ist ein Pflichfeld' : (showYearHint ? 'Bitte nur das Geburtsjahr als vierstellige Zahl eintragen' : '')}
              id='birthYear'
              label='Geburtsjahr'
              name='birthYear'
              value={birthYear}
              onChange={(event) => setBirthYear(event.currentTarget.value)}
            />
          </Grid>
          <Grid item xs={downMd ? 12 : 4}>
            <DropDownComponent
              fullWidth
              error={showRequiredHints && !gender}
              helperText={showRequiredHints && !gender ? 'Geschlecht ist ein Pflichfeld' : ''}
              options={[
                {
                  key: 'Male',
                  value: 'Mann',
                },
                {
                  key: 'Female',
                  value: 'Frau',
                },
                {
                  key: 'Diverse',
                  value: 'Divers',
                },
              ]}
              disableClearable
              value={gender}
              label='Geschlecht'
              name='gender'
              onChange={(value) => setGender(value)}
            />
          </Grid>
          <Grid item xs={downMd ? 12 : 4}>
            {areaList.length > 0 &&
              <DropDownComponent
                fullWidth
                options={areaList}
                disableClearable
                value={preferedArea}
                label='Bevorzugter Bereich'
                name='preferedArea'
                onChange={(value) => setPreferedArea(value)}
              />}
          </Grid>
          <Grid item xs={downMd ? 12 : 4}>
            <CheckboxComponent
              checked={shirt}
              label='Ich brauche ein T-Shirt'
              name='shirt'
              onChange={() => setShirt(!shirt)}
            />
          </Grid>
          <Grid item xs={12}><Typography sx={{ marginTop: '20px' }} variant='h5'>Einsatzzeiten</Typography> </Grid>
          {showHint &&
            <Typography sx={{ color: theme.palette.primary.main, marginTop: '10px', marginLeft: '16px' }} variant='body1'>Mindestens ein Tag muss ausgewählt sein!</Typography>}
          <Grid item xs={downMd ? 12 : 4}>
            <CheckboxComponent
              checked={availableOnFriday}
              id='availableOnFriday'
              name='availableOnFriday'
              label='Einsatz am Freitag, den 13.06.2025'
              onChange={(value) => setAvailableOnFriday(value)}
            />
          </Grid>
          <Grid item xs={downMd ? 12 : 4}>
            <TimePicker
              label='von'
              value={fridayFrom}
              onChange={(value) => setFridayFrom(value)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid item xs={downMd ? 12 : 4}>
            <TimePicker
              label='bis'
              value={fridayTo}
              minTime={fridayFrom}
              onChange={(value) => setFridayTo(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={showTimeHint && availableOnFriday && fridayFrom?.isAfter(fridayTo)}
                  helperText={showTimeHint && availableOnFriday && fridayFrom?.isAfter(fridayTo) ? 'Endzeit muss nach der Anfangszeit liegen' : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={downMd ? 12 : 4}>
            <CheckboxComponent
              checked={availableOnSaturday}
              id='availableOnSaturday'
              name='availableOnSaturday'
              label='Einsatz am Samstag, den 14.06.2025'
              onChange={(value) => setAvailableOnSaturday(value)}
            />
          </Grid>
          <Grid item xs={downMd ? 12 : 4}>
            <TimePicker
              label='von'
              value={saturdayFrom}
              onChange={(value) => setSaturdayFrom(value)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid item xs={downMd ? 12 : 4}>
            <TimePicker
              label='bis'
              value={saturdayTo}
              minTime={saturdayFrom}
              onChange={(value) => setSaturdayTo(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={showTimeHint && availableOnSaturday && saturdayFrom?.isAfter(saturdayTo)}
                  helperText={showTimeHint && availableOnSaturday && saturdayFrom?.isAfter(saturdayTo) ? 'Endzeit muss nach der Anfangszeit liegen' : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={downMd ? 12 : 4}>
            <CheckboxComponent
              checked={availableOnSunday}
              id='availableOnSunday'
              name='availableOnSunday'
              label='Einsatz am Sonntag, den 15.06.2025'
              onChange={(value) => setAvailableOnSunday(value)}
            />
          </Grid>
          <Grid item xs={downMd ? 12 : 4}>
            <TimePicker
              label='von'
              value={sundayFrom}
              onChange={(value) => setSundayFrom(value)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid item xs={downMd ? 12 : 4}>
            <TimePicker
              label='bis'
              value={sundayTo}
              minTime={saturdayFrom}
              onChange={(value) => setSundayTo(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={showTimeHint && availableOnSunday && sundayFrom?.isAfter(sundayTo)}
                  helperText={showTimeHint && availableOnSunday && sundayFrom?.isAfter(sundayTo) ? 'Endzeit muss nach der Anfangszeit liegen' : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}><Typography sx={{ marginTop: '20px' }} variant='h5'>Weitere Informationen</Typography>
          </Grid>
          <Grid item xs={12}>
            <CheckboxComponent
              checked={helpedBefore}
              id='helpedBefore'
              name='availableOnSunday'
              label='Ich habe schon mal beim Schloss-Triathlon Moritzburg geholfen'
              onChange={(value) => setHelpedBefore(value)}
            />
          </Grid>
          <Grid item xs={12}>
            <CheckboxComponent
              checked={isClubMember}
              id='isClubMember'
              name='isClubMember'
              label='Ich bin ein offizielles Vereinsmitglied des Moritzburger Triathlonverein e.V.'
              onChange={(value) => setIsClubMember(value)}
            />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '20px' }}>
            <LabelWithTooltipComponent
              label='Eine/Ein Freundin/Freund wird auch mithelfen. Wir möchten gern gemeinsam eingeteilt werden.'
            />
          </Grid>
          <Grid item xs={downMd ? 12 : 6}>
            <TextField
              margin='normal'
              fullWidth
              id='friend'
              label='Name des Freundes'
              name='friend'
              value={friend}
              onChange={(event) => setFriend(event.currentTarget.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin='normal'
              fullWidth
              multiline
              minRows={3}
              id='notes'
              label='Besondere Fähigkeiten und Wünsche'
              name='notes'
              value={notes}
              onChange={(event) => setNotes(event.currentTarget.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              <Button
                type='submit'
                variant='contained'
                color='primary'
                onClick={() => {
                  if (disabled) {
                    if (availableOnFriday || availableOnSaturday || availableOnSunday) {
                      setShowHint(false)
                    } else {
                      setShowHint(true)
                    }
                    if (parseInt(birthYear, 10) < 1900 || parseInt(birthYear, 10) > new Date().getFullYear()) {
                      setShowYearHint(true)
                    } else {
                      setShowYearHint(false)
                    }
                    if (emailAddress && !validateEmail(emailAddress)) {
                      setShowYearHint(true)
                    } else {
                      setShowYearHint(false)
                    }
                    if (!firstName || !lastName || !telephoneNumber || !emailAddress || !address || !gender || !birthYear) {
                      setShowRequiredHints(true)
                    } else {
                      setShowRequiredHints(false)
                    }
                  } else {
                    registerHelper()
                  }
                }}
              >
                Anmelden
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </LocalizationProvider>
  )
}
export default Registration
